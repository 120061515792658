export const FormatRelativeDate = (date) => {
  const oneDay = 1000 * 60 * 60 * 24;
  
  const timeFormatter = new Intl.DateTimeFormat('en-GB', { hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
  const dateFormatter = new Intl.DateTimeFormat('en-GB', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZoneName: 'short' });
  const dayFormatter = new Intl.DateTimeFormat('en-GB', {weekday: 'long', hour: 'numeric', minute: 'numeric', timeZoneName: 'short'})
  const relativeFormatter = new Intl.RelativeTimeFormat("en", { localeMatcher: "best fit", numeric: "auto", style: "long", hour: 'numeric', minute: 'numeric', timeZoneName: 'short'});
  
  var dayDifference = 1;

  var postDayDifference = Math.round((new Date(date) - Date.now()) / oneDay);

  if (dayDifference !== postDayDifference) {
      dayDifference = postDayDifference;
      if (dayDifference >= -1) {
          return relativeFormatter.format(dayDifference, 'day') + ", " + timeFormatter.format(new Date(date))
      } else if (dayDifference <= -2 && dayDifference >= -7) {
          return dayFormatter.format(new Date(date))
      } else {
          return dateFormatter.format(new Date(date))
      };
  }
}