import React, {useReducer, useEffect} from 'react';

import './App.scss';
import Header from './components/common/header/Header';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { QueryClient, QueryClientProvider } from 'react-query'
import Processes from "./components/processes/Processes";
import ProcessDetail from './components/process-detail/ProcessDetail';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { addAccessTokenInterceptor } from './services/common/axios-config'

const userInit = {userInfo: {}}

const userReducer = (state, action) => {
  let userInformation = typeof (action) !== "string" ? action : state.userInfo;
  return {
    ...state,
    userInfo: userInformation
  };
}

export const UserContext = React.createContext(userInit);
export const queryClient = new QueryClient();

const ProtectedComponent = ({ component, ...props}) => {
  const Component = withAuthenticationRequired(component);
  return <Component {...props} />
}

function App() {
  const [user, dispatch] = useReducer(userReducer, userInit);
  const {getAccessTokenSilently} = useAuth0();
  
  useEffect(() => {
    addAccessTokenInterceptor(getAccessTokenSilently)
  })

  return (
    <Router>
      <UserContext.Provider value={{userState: user, userDispatcher: dispatch}}>
        <QueryClientProvider client={queryClient}>
            <Header/>
            <Switch>
              <Route path="/" exact>
                <Redirect to="/processes" />
              </Route>
              <Route path="/processes" render={() => <ProtectedComponent component={Processes} />} />
              <Route path="/process/:processId" render={() => <ProtectedComponent component={ProcessDetail} />} />
            </Switch>
        </QueryClientProvider>
      </UserContext.Provider>
    </Router>
  );
}

export default App;
