import React, {useState, useEffect, useRef} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from 'primereact/button';
import {useQuery} from "react-query";
import {GetProcessDetailData, UploadDataFile} from "../../services/processes-service";
import {FormatRelativeDate} from "../../services/formatter-service";
import {noRefetchOnWindowFocus} from "../../services/common/useQuery-config";
import settingsBlack from "../../assets/images/icon/settings-black.svg";

import "./ProcessDetail.scss";
import {FileUpload} from "primereact/fileupload";
import {Dialog} from "primereact/dialog";

const ProcessDetail = () => {
  const [processDetailData, setProcessDetailData] = useState(null);
  const [processDetailDataJson, setProcessDetailDataJson] = useState([]);
  const url = window.location.href;
  const urlId = url.split("/").pop();

  let uploadId = useRef([]);
  let processLogs = useRef([]);
  let processValidationErrors = useRef([]);
  let processOtherErrors = useRef([]);
  let processImports = useRef([]);

  const processLogInfoTbl = useRef(null);
  const processValidationErrorsTbl = useRef(null);
  const processOtherErrorsTbl = useRef(null);
  const processImportsTbl = useRef(null);

  const [uploadSucceeded, setUploadSucceeded] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);

  const {isLoading, data} = useQuery(["processDetailData", parseInt(urlId), uploadSucceeded], GetProcessDetailData, noRefetchOnWindowFocus );
  useEffect(() => {
    if(data) {
      setProcessDetailData(data);
      if(processDetailData) {
        setProcessDetailDataJson(JSON.parse(processDetailData.configJSON));
        uploadId.current = `uploadBtn_${data.id}`;
        processLogs.current = processDetailData.logInfoTbl;
        processOtherErrors.current = processDetailData.otherErrorsTbl;
        processValidationErrors.current = processDetailData.validationErrorsTbl;
        processImports.current = processDetailData.importsTbl
      }
    }
  }, [processDetailData, data, urlId]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const messageTemplate = (rowData) => {
    return (
      <div className="padding-cursor type-wrap">
        <span className="p-column-title">Message</span>
        <div className="col-12">
          {rowData._source.message}
        </div>
      </div> 
    );
  };

  const processIdTemplate = (rowData) => {
    return (
      <div className="padding-cursor type-wrap">
        <span className="p-column-title">Process Id</span>
        <div className="col-12">
          {rowData._source.processId}
        </div>
      </div> 
    );
  };

  const dateTemplate = (rowData) => {        
    const dateCreated = rowData._source.created === 'Never run' ? 'Never run' : FormatRelativeDate(new Date(rowData._source.created)).toString()
    return (
      <div className="padding-cursor type-wrap">
        <span className="p-column-title">Timestamp</span>
        <div className="col-12">
          {dateCreated}
        </div>
      </div> 
    );
  };

  const importsTemplate = (rowData, colData) => {
    return (
      <div className="padding-cursor type-wrap">
        <span className="p-column-title">{colData.header}</span>
        <div className="col-12">
          {
            (colData.bodyField === "consumptionTo" || colData.bodyField === "consumptionFrom") && rowData._source[colData.bodyField] ? 
              new Date(rowData._source[colData.bodyField]).toLocaleDateString('en-GB') :              
                colData.bodyField === "consumptionValue" && rowData._source[colData.bodyField] ?
                  parseFloat(rowData._source[colData.bodyField].toFixed(2)) :
                    colData.bodyField === "created" && rowData._source[colData.bodyField] ?
                      FormatRelativeDate(rowData._source[colData.bodyField]).toString() :
                        rowData._source[colData.bodyField]
          }
        </div>
      </div> 
    );
  };

  const templatePaginatorLogInfo = {
    layout: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport',
    'PrevPageLink': (options) => <Button type="button" icon="pi pi-angle-left" className={options.className} onClick={options.onClick} disabled={options.disabled} />,
    'NextPageLink': (options) => <Button type="button" icon="pi pi-angle-right" className={options.className} onClick={options.onClick} disabled={options.disabled} />
  };

  const uploadHandler = (event) => {
    let importConfigId = event.options.props.id.split('_')[1];
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      UploadDataFile(e.target.result, event.files[0], importConfigId)
        .then((result) => {
          if(result) {
            setUploadSucceeded(true);
          } else {
            setUploadFailed(true);
          }
        })
        .catch((error) => {
          console.log(error.message);
          setUploadFailed(true)
        });
    };

    fileReader.readAsDataURL(event.files[0]);

    event.options.clear();
  }

  return (
    <div className="process-detail">
      {!isLoading && 
        <div className="display wrap-center">
          <div className="process-wrapper">
            {isLoading ?
              <>
                <DataTable value={[]} className="datatable-gridlines"/>
                Loading....
              </>
              : 
              <>
                {processDetailData ?
                  <div className="process-details-wrap">
                    <div className="col-12 no-padding-left-right processes-logo">
                      <span><img src={settingsBlack} alt="Processes Page" style={{width: '1.25em', height: '1.25em', display: 'block'}} /></span>
                      <span>{processDetailData.importName}</span>
                    </div>
                    <div className="process-content-wrap">
                      <div>
                        <p><span>Process type: </span> {processDetailDataJson.details && processDetailDataJson.details.name}</p>
                        <p><span>Version number: </span>{processDetailDataJson.details && processDetailDataJson.details.version}</p>
                        <p><span>Delivery: </span>{processDetailDataJson.delivery && processDetailDataJson.delivery.type}</p>
                        <p><span>Location: </span>{processDetailDataJson.delivery && processDetailDataJson.delivery.location}</p>
                        <p><span><FileUpload className="upload-config-btn" id={uploadId.current} mode="basic" name="dataBtn" accept=".json,.csv"
                                             customUpload={true} auto={true} chooseOptions={{label: 'Upload file'}}
                                             uploadHandler={uploadHandler}/></span></p>
                      </div>
                      <div>
                        <p><span>Username: </span>{processDetailDataJson.delivery && processDetailDataJson.delivery.user[0]}</p>
                        <p className="password-process-content"><span>Password: </span>{processDetailDataJson.delivery && processDetailDataJson.delivery.pass[0]}</p>
                        <p><span>Translation: </span>{processDetailDataJson.translation && processDetailDataJson.translation[0].type}</p>
                      </div>
                    </div>
                    <Dialog header="File Upload" visible={uploadSucceeded} style={{ width: '50vw' }} onHide={() => {if (!uploadSucceeded) { return } setUploadSucceeded(false); }}>
                        <p className="m-0">
                            File uploaded successfully
                        </p>
                    </Dialog>
                    <Dialog header="File Upload" visible={uploadFailed} style={{ width: '50vw' }} onHide={() => {if (!uploadFailed) { return } setUploadFailed(false); }}>
                        <p className="m-0">
                            File was not uploaded
                        </p>
                    </Dialog>
                  </div>
                  :
                  null
                }
                
                {
                  <div className="data-table">
                    <DataTable
                      paginator={processImports.current.length > 0}
                      paginatorTemplate={templatePaginatorLogInfo}
                      rows={10}
                      alwaysShowPaginator={false} 
                      ref={processImportsTbl} 
                      value={processImports.current} 
                      header="Successful Imports"
                      sortMode="multiple"
                      className="datatable-gridlines" 
                      emptyMessage={'No successful imports'}>
                      <Column
                        bodyField="clientName"
                        field="_source.clientName"
                        body={importsTemplate}
                        header="Client Name"
                        headerClassName="table-header-style"
                        style={{'width': '20%'}}
                        sortable />
                      <Column
                        bodyField="mPAN"
                        field="_source.mPAN"
                        body={importsTemplate}
                        header="Meter MPAN"
                        headerClassName="table-header-style"
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="consumptionFrom"
                        field="_source.consumptionFrom"
                        body={importsTemplate}
                        header="Consumption Start"
                        headerClassName="table-header-style"
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="consumptionTo"
                        field="_source.consumptionTo"
                        body={importsTemplate}
                        header="Consumption End"
                        headerClassName="table-header-style"
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="consumptionValue"
                        field="_source.consumptionValue"
                        body={importsTemplate}
                        header="Consumption Value"
                        headerClassName="table-header-style"
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        sortable/>                        
                        <Column
                          bodyField="created"
                          field="_source.created"
                          body={importsTemplate}
                          header="Date Imported"
                          headerClassName="table-header-style"
                          style={{'width': '20%',
                          textAlign: 'left'}}
                          sortable/>
                    </DataTable>
                  </div>
                }
                <hr className="horizontal-line"/>
                {
                  <div className="data-table">
                    <DataTable
                      paginator={processValidationErrors.current.length > 0}
                      paginatorTemplate={templatePaginatorLogInfo}
                      rows={10}
                      alwaysShowPaginator={false}
                      ref={processValidationErrorsTbl} 
                      value={processValidationErrors.current} 
                      header="Validation Errors"
                      sortMode="multiple"
                      className="datatable-gridlines text-wrap" 
                      emptyMessage={'No validation errors'}>
                      <Column
                        bodyField="message" 
                        field="_source.message" 
                        header="Message"
                        headerClassName="table-header-style" 
                        body={messageTemplate}
                        style={{'width': '30%', 
                        textAlign: 'left'}}
                        className={""}
                        sortable/>
                      <Column
                        bodyField="clientName"
                        field="_source.clientName"
                        body={importsTemplate}
                        header="Client Name"
                        headerClassName="table-header-style"
                        style={{'width': '14%'}}
                        sortable />
                      <Column
                        bodyField="mPAN"
                        field="_source.mPAN"
                        body={importsTemplate}
                        header="Meter MPAN"
                        headerClassName="table-header-style"
                        style={{'width': '14%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="consumptionFrom"
                        field="_source.consumptionFrom"
                        body={importsTemplate}
                        header="Consumption Start"
                        headerClassName="table-header-style"
                        style={{'width': '14%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="consumptionTo"
                        field="_source.consumptionTo"
                        body={importsTemplate}
                        header="Consumption End"
                        headerClassName="table-header-style"
                        style={{'width': '14%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="consumptionValue"
                        field="_source.consumptionValue"
                        body={importsTemplate}
                        header="Consumption Value"
                        headerClassName="table-header-style"
                        style={{'width': '14%',
                        textAlign: 'left'}}
                        sortable/>
                      <Column
                        bodyField="created"
                        field="_source.created"
                        body={importsTemplate}
                        header="Date Imported"
                        headerClassName="table-header-style"
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        sortable/>
                    </DataTable>
                  </div>
                }
                <hr className="horizontal-line"/>
                {
                  <div className="data-table">
                    <DataTable  
                      ref={processOtherErrorsTbl} 
                      value={processOtherErrors.current} 
                      header="Other Errors"
                      sortMode="multiple"
                      className="datatable-gridlines" 
                      emptyMessage={'No other errors'}>
                      <Column 
                        field="_source.message" 
                        header="Message"
                        headerClassName="table-header-style" 
                        body={messageTemplate}
                        style={{'width': '50%', 
                        textAlign: 'left'}}
                        className={""}
                        sortable/>
                      <Column 
                        field="_source.processId" 
                        header="Process Id"
                        headerClassName="table-header-style" 
                        body={processIdTemplate}
                        style={{'width': '30%', 
                        textAlign: 'left'}}
                        className={""}
                        sortable/>
                      <Column 
                        field="_source.created"
                        header="Timestamp"
                        headerClassName="table-header-style"
                        body={dateTemplate} 
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        className={""} 
                        sortable/>
                    </DataTable>
                  </div>
                }
                <hr className="horizontal-line"/>  
                {
                  <div className="data-table">
                    <DataTable
                      ref={processLogInfoTbl} 
                      value={processLogs.current} 
                      header="Log Information"
                      sortMode="multiple"
                      className="datatable-gridlines" 
                      emptyMessage={'No logs'}>
                      <Column
                        field="_source.message" 
                        header="Message"
                        headerClassName="table-header-style" 
                        body={messageTemplate}
                        style={{'width': '50%', 
                        textAlign: 'left'}}
                        className={""}
                        sortable/>
                      <Column 
                        field="_source.processId" 
                        header="Process Id"
                        headerClassName="table-header-style" 
                        body={processIdTemplate}
                        style={{'width': '30%', 
                        textAlign: 'left'}}
                        className={""}
                        sortable/>
                      <Column 
                        field="_source.created"
                        header="Timestamp"
                        headerClassName="table-header-style"
                        body={dateTemplate} 
                        style={{'width': '20%',
                        textAlign: 'left'}}
                        className={""} 
                        sortable/>
                    </DataTable>
                  </div>
                }
              </>
            }
          </div>
        </div>
      }
    </div>
  )
};
export default ProcessDetail;