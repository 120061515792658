import React, {useState, useEffect, useRef, createContext} from 'react';
import {useHistory} from 'react-router-dom';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {Dropdown} from 'primereact/dropdown';
import {useQuery} from "react-query";
import {GetProcessesData} from "../../services/processes-service";
import {noRefetchOnWindowFocus} from "../../services/common/useQuery-config";
import settingsBlack from "../../assets/images/icon/settings-black.svg";
import files from "../../assets/images/icon/files-grey.svg";
import valid from "../../assets/images/icon/valid.svg";
import errors from "../../assets/images/icon/errors.svg";
import lastRun from "../../assets/images/icon/last-run.svg";
import schedule from "../../assets/images/icon/schedule.svg";
import play from "../../assets/images/icon/play-icon.svg";
import stop from "../../assets/images/icon/stop-icon.svg";
import cronstrue from 'cronstrue';
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import "./Processes.scss";

const processTypeOptions = [
  {label: 'All', value: ''},
  {label: 'Asset bulk export', value: 'abe'},
  {label: 'Bulk data export', value: 'bde'},
  {label: 'Half hourly data download', value: 'hhdd'},
  {label: 'Half hourly data import', value: 'hhdi'},
  {label: 'Meter consumption import', value: 'mci'},
  {label: 'Siera data download', value: 'sdd'}
];

export const ProcessTypeOptionsContext = createContext(processTypeOptions);
TimeAgo.addDefaultLocale(en);

const Processes = () => {
  const [processesData, setProcessesData] = useState([]);
  const [dataSize, setDataSize] = useState(0);
  const [noData, setNoData] = useState(false);
  const [multiSortMeta, setMultiSortMeta] = useState([{ field: 'name', order: 1 }]);
  const history     = useHistory();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  let processes = useRef([]);

  const processesDataTableRef = useRef(null);
  
  const filterStatusOptions = [
    {label: 'All', value: ''},
    {label: 'Active', value: 'active'},
    {label: 'Stopped', value: 'stopped'}
  ];

  const {isLoading, data} = useQuery('processesData', GetProcessesData, noRefetchOnWindowFocus);
  
  const timeAgo = new TimeAgo('en-US');
  
  useEffect(() => {
    setProcessesData(data);
    
    if (processesData && processesData.length > 0) {
      setNoData(false);
      setDataSize(processesData.length);

      processes.current = data;

    } else {
      if(!isLoading)
        setNoData(true)
    }
  }, [data, processesData]);

  const processTemplate = (rowData, colData) => {
    return (
      <div onClick={openProcess(rowData, colData)} className="cursor">
        <span className="p-column-title">Process</span>
        <div>
          <div className="col-12">
            <p className="bold-text"> {rowData.importName} </p>
          </div>
          <div className="process-details">
          <div className='flex-wrapper col-5'>
            <div>
              <p>
                <span>
                  <img src={files} alt="Files" />
                  Files processed <span className="mobile-vis">:</span>
                </span>
                <span>{rowData.files}</span>
              </p>
            </div>
            <div>
              <p>
                <span>
                  { rowData.errors === 0 ?
                    <img src={valid} alt="Valid" /> :
                    <img src={errors} alt="Errors" />
                  } 
                  Errors <span className="mobile-vis">:</span>
                </span> 
                { <span>{rowData.errors}</span>}
              </p>
            </div>
          </div>
          <div className='flex-wrapper col-7'>
          <div>
            <p>
              <span>
                <img src={schedule} alt="Schedule" />
                Schedule <span className="mobile-vis">:</span>
              </span>
              <span>{cronstrue.toString(rowData.cronExpression)}</span>
            </p>
          </div>
          <div>
            <p>
              <span className="last-run-span">
                <img src={lastRun} alt="Last Run" />
                Last run <span className="mobile-vis">:</span>
              </span>
              { <span>{rowData.lastRun === 'Never run' ? 'Never run' : timeAgo.format(new Date(rowData.lastRun), 'round')}</span> }
            </p>
          </div>
          
        </div>
        </div>
        </div>
      </div>
    );
  }

  const typeTemplate = (rowData) => {
    const processType = JSON.parse(rowData.configJSON).details.name;
    return (
      <div className="padding-cursor type-wrap">
        <span className="p-column-title">Type</span>
        <div className="col-12">
          {processType}
        </div>
      </div> 
    );
  }

  const actionTemplate = (rowData) => {
    return (
      <div className="padding-cursor action-wrap">
        <span className="p-column-title">Action</span>
        <div className="col-12">
          {
            rowData.status === 'active' ?
            <Button
              onClick={onActionClick} 
              className="p-button-link action-btn action-btn--stop">
                <img src={stop} alt="Stop" />
                <span>Stop</span>
            </Button> : 
            <Button
              onClick={onActionClick} 
              className="p-button-link action-btn action-btn--start">
                <img src={play} alt="Start" />
                <span>Start</span>
            </Button> 
          }
        </div>
      </div>
    );
  }

  const statusTemplate   = (rowData) => {
    let colorDot = '';
    rowData.status === 'active' ? colorDot = 'green-dot' : colorDot = 'red-dot';
    const statusDescriptionArray = filterStatusOptions.filter(obj => {
      return obj.value === rowData.status
    });

    return (
      statusDescriptionArray.length > 0 ? 
      <div className="padding-cursor">
        <span className="p-column-title">Status</span>
        <div className="status-dot">
          <div className="p-col-12 center-dot">
            <div className={colorDot}/>
          </div>
          <div className="col-12">
          {statusDescriptionArray[0].label }
        </div>
        </div>
      </div> :
      null
    );
  }

  const dropdownItemTemplate  = (option) => {
    return (
      <>
        <div className="dropdown-item-1">
          <div className="dropdown-item-2 dropdown-item-wrap">{option.label}</div>
        </div>
        <div className={`dropdown-panel-item-horiz-line`}></div>
      </>
    );
  };

  const onActionClick = (e) => {
    console.log(e.target);
  }

  const onSort = (e) => {
    setMultiSortMeta(e.multiSortMeta);
  }

  const openProcess = (rowData) => () => {
    history.push(`/process/${rowData.id}`)
  }

  const clearFilter = () => {
    processes.current = processesData;
    setDataSize(processesData.length);
    setSelectedStatus(null);
    setSelectedType(null);
    processesDataTableRef.current.reset();
  }

  const onTypeChange = (e) => {
    processesDataTableRef.current.filter(e.value, 'type', 'equals');
    setSelectedType(e.value);
  }

  const onStatusChange = (e) => {
    processesDataTableRef.current.filter(e.value, 'status', 'equals');
    setSelectedStatus(e.value);
  }

  const statusFilter = 
  <Dropdown  
    options={filterStatusOptions}
    optionLabel="label"
    value={selectedStatus} 
    className="sDropdown" 
    onChange={onStatusChange} 
    itemTemplate={dropdownItemTemplate} 
    scrollHeight="24em"
    placeholder="All"
    disabled={noData}
  />

  const typeFilter = 
  <Dropdown  
    options={processTypeOptions}
    optionLabel="label"
    value={selectedType} 
    className="sDropdown" 
    onChange={onTypeChange}
    itemTemplate={dropdownItemTemplate} 
    scrollHeight="24em"
    placeholder="All"
    disabled={noData}
  />

  const header = (
    <div className="table-header">
      <div className="col-12 no-padding-left-right processes-logo">
        <span><img src={settingsBlack} alt="Processes Page" style={{width: '1.25em', height: '1.25em', display: 'block'}} /></span>
        <span>Processes</span>
      </div>
    </div>
  );

  return (
    <div className="processes">
      {!isLoading && 
        <div className="display wrap-center">
          <div className="processes-wrapper">
            {isLoading ?
              <>
                <DataTable value={[]} header={header} className="datatable-gridlines"/>
                Loading....
              </>
              : 
              <>
                {noData ?
                  <>
                    <DataTable value={[]} header={header} className="datatable-gridlines"/>
                    {'No data found'}
                  </>
                  :
                  <>
                    <div className={`${dataSize ? 'processes-data-table' : 'no-data-msg'}`}>
                        <DataTable  
                          ref={processesDataTableRef} 
                          value={processes.current} 
                          header={header} 
                          multiSortMeta={multiSortMeta} 
                          onSort={onSort}
                          sortMode="multiple"
                          className="datatable-gridlines" 
                          emptyMessage={'No data found'}>
                          <Column 
                            field="importName"
                            header={dataSize ? "Process" : ""}
                            headerClassName="table-header-style"
                            body={processTemplate} 
                            style={{'width': '40%'}}
                            className={""} 
                            sortable />
                          <Column
                            field="importName" 
                            header={dataSize ? "Type" : ""}
                            headerClassName="table-header-style" 
                            body={typeTemplate}
                            style={{'width': '30%', textAlign: 'left'}}
                            className={""}
                            sortable
                            filter 
                            filterElement={typeFilter}/>
                          <Column
                            header={dataSize ? "Action" : ""}
                            headerClassName="table-header-style"
                            body={actionTemplate} 
                            style={{'width': '15%', textAlign: 'center', display: 'none'}}
                            className={""} hidden />
                          <Column
                            field="status" 
                            header={dataSize ? "Status" : ""}
                            headerClassName="table-header-style"
                            body={statusTemplate} 
                            style={{'width': '15%', textAlign: 'center', display: 'none'}}
                            className={""} 
                            sortable
                            filter 
                            filterElement={statusFilter}/>
                        </DataTable>
                      </div>
                  </>
                }
              </>
            }
          </div>
        </div>
      }
    </div>
  )
}
export default Processes;
