import axios from "axios";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

let axiosInstance = axios.create({
  baseURL: baseURL,
});

export const addAccessTokenInterceptor = (getAccessTokenSilently) => {
  axiosInstance.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently()
    config.headers['authorization'] = 'Bearer ' + token
    return config
  })
}

export default axiosInstance;