const retryCount = 0;

export const noRefetchOnWindowFocus = {
  refetchOnWindowFocus: false,
  retry: retryCount
  // enabled: false // turned off by default, manual refetch is needed
}

export const manualFetch = {
  refetchOnWindowFocus: false,
  enabled: false, // turned off by default, this enables manual fetch
  retry: retryCount
}

