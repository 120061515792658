const alphabet = [...Array(26)].map((q,w)=>String.fromCharCode(w+97));

export const GetAuthItem = (first, second, third, fourth) => {
  const firstCharToArray = [];
  const secondCharToArray = [];
  const thirdCharToArray = [];
  const fourthCharToArray = [];
    first.forEach(a => {
      if(a.modifier === 'u') {
        firstCharToArray.push(alphabet[a.item].toUpperCase());
      }
      if(a.modifier === 'l') {
        firstCharToArray.push(alphabet[a.item]);
      }
      if(a.modifier === 'n' || a.modifier === 'c') {
        firstCharToArray.push(a.item);
      }
    });
    
    second.forEach(a => {
      if(a.modifier === 'u') {
        secondCharToArray.push(alphabet[a.item].toUpperCase());
      }
      if(a.modifier === 'l') {
        secondCharToArray.push(alphabet[a.item]);
      }
      if(a.modifier === 'n' || a.modifier === 'c') {
        secondCharToArray.push(a.item);
      }
    });
    
    third.forEach(a => {
      if(a.modifier === 'u') {
        thirdCharToArray.push(alphabet[a.item].toUpperCase());
      }
      if(a.modifier === 'l') {
        thirdCharToArray.push(alphabet[a.item]);
      }
      if(a.modifier === 'n' || a.modifier === 'c') {
        thirdCharToArray.push(a.item);
      }
    });
    
    fourth.forEach(a => {
      if(a.modifier === 'u') {
        fourthCharToArray.push(alphabet[a.item].toUpperCase());
      }
      if(a.modifier === 'l') {
        fourthCharToArray.push(alphabet[a.item]);
      }
      if(a.modifier === 'n' || a.modifier === 'c') {
        fourthCharToArray.push(a.item);
      }
    });
    
    const translateItem = `${fourthCharToArray.join('')}${thirdCharToArray.join('')}${secondCharToArray.join('')}${firstCharToArray.join('')}`
  
   return translateItem;
  }