import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import reportWebVitals from './reportWebVitals';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './index.scss';
import App from './App';

console.log('process.env.REACT_APP_AUTH0_DOMAIN', process.env.REACT_APP_AUTH0_DOMAIN)
console.log('process.env.REACT_APP_AUTH0_AUDIENCE', process.env.REACT_APP_AUTH0_AUDIENCE);
console.log('process.env.REACT_APP_AUTH0_CALLBACK_URL', process.env.REACT_APP_AUTH0_CALLBACK_URL);

ReactDOM.render(
  <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL
      }}
    >
      <App />
    </Auth0Provider>,
    document.getElementById('root')
  );
  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
